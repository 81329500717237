import React, { useCallback, useEffect, useState } from "react";
// import { DAppProvider } from "@usedapp/core";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Alert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { BrowserProvider, Contract, ethers } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { isMobile } from "react-device-detect";

import { api, postForm } from "../../services/api";

// import ArtMarketplace from "../../contracts/ArtMarketplace.json";
import abiMarket from "./abiMarket.js";
import networksMarket from "./networksMarket.js";

// import ArtToken from "../../contracts/ArtToken.json";
import abiToken from "./abiToken.js";
import networksToken from "./networksToken.js";

import Card from "../../components/Card";

import { useStyles } from "./styles.js";

import veterans from "../../assets/arts/Sparse-Ahmed-Mostafa-vetarans-2.jpg";
import lionKing from "../../assets/arts/suresh-pydikondala-lion.jpg";
import dreaming from "../../assets/arts/phuongvp-maybe-i-m-dreaming-by-pvpgk-deggyli.jpg";
import modeling3d from "../../assets/arts/alan-linssen-alanlinssen-kitbashkitrender2.jpg";
import woman from "../../assets/arts/ashline-sketch-brushes-3-2.jpg";
import stones from "../../assets/arts/rentao_-22-10-.jpg";
import wale from "../../assets/arts/luzhan-liu-1-1500.jpg";
import comic from "../../assets/arts/daniel-taylor-black-and-white-2019-2.jpg";
import galerie from "../../assets/galerie.svg";

import axios from "axios";

import { Buffer } from "buffer";
import Header from "../../components/Header";

// @ts-ignore
window.Buffer = Buffer;

// const abiMarket = ArtMarketplace.abi;
// const networksMarket = ArtMarketplace.networks;

// const abiToken = ArtToken.abi;
// const networksToken = ArtToken.networks;

const Home = () => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState("");
  const [myAddress, setMyAddress] = useState("");

  const [openBuyDialog, setOpenBuyDialog] = React.useState(false);
  const [openSellDialog, setOpenSellDialog] = useState(false);

  const [addressToken, setAddressToken] = useState("");
  const [addressMarket, setAddressMarket] = useState("");

  const [selectedItem, setSelectedItem] = useState(null);
  const [nfts, setNfts] = useState([]);

  const [sellPrice, setSellPrice] = useState(1000);
  const [loading, setLoading] = useState(false);

  const getProvider = async () => {
    const browserProvider = await detectEthereumProvider();
    const provider = new BrowserProvider(browserProvider);

    // const provider = new ethers.BrowserProvider(window.ethereum);

    return provider;
  };

  const getJsonRpcProvider = async () => {
    const provider = await ethers.getDefaultProvider(
      "https://http-testnet.chain.pixie.xyz"
    );

    return provider;
  };

  const loadAigc = async ({ filterIn, pageIn, myAddressIn }) => {
    setLoading(true);

    let itemsList = [];
    const aigcResponse = await api
      .get(`/aigc-for-nft`, {
        params: {
          page: pageIn !== undefined ? pageIn : page,
          address: myAddressIn !== undefined ? myAddressIn : myAddress,
          filter: filterIn !== undefined ? filterIn : filter,
        },
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
    let aigcs = aigcResponse.data.data.data;
    if (aigcs === null) {
      aigcs = [];
    }

    const total = aigcResponse.data.data.total;

    // console.log("AigcResponse", total);

    try {
      const provider = await getJsonRpcProvider();

      const network = await provider.getNetwork();
      const networkId = network.chainId.toString();

      const addressToken = networksToken[networkId].address;
      const addressMarket = networksMarket[networkId].address;

      const artTokenContract = new ethers.Contract(
        addressToken,
        abiToken,
        provider
      );

      const marketplaceContract = new ethers.Contract(
        addressMarket,
        abiMarket,
        provider
      );

      // try {
      //   artTokenContract
      //     // .ownerOf(5)
      //     .totalSupply()
      //     .then((a) => console.log("test1: ", a))
      //     // .catch((err) => console.error(err))
      //     .finally((a) => console.log("test2", a));
      //   // console.log(owner);
      // } catch (err) {
      //   console.error("test:", err);
      // }
      // console.log("End...");

      for (let i = 0; i < aigcs.length; i++) {
        const item = aigcs[i];
        let owner = item.public_key;
        let price = 0;
        let isSold = false;

        if (item.nft_id > 0) {
          try {
            // console.log("ownerOf", item.nft_id);
            owner = await artTokenContract.ownerOf(item.nft_id);
          } catch (err) {
            console.error(err);
          }
        }

        if (item.sale_id > -1) {
          try {
            // console.log("itemsForSale", item.sale_id);
            let itemForSale = await marketplaceContract.itemsForSale(
              item.sale_id
            );
            // console.log("itemsForSale", itemForSale);

            price = itemForSale.price;
            isSold = itemForSale.isSold;
          } catch (err) {
            console.error(err);
          }
        }

        itemsList.push({
          name: item.nickname,
          description: item.content,
          image: `https://images.pixie.xyz/text-to-image/${item.account}/${item.img_url}`,
          tokenId: item.nft_id,
          creator: item.public_address,
          owner: owner,
          uri: item.uri,
          isForSale: item.sale_id > -1,
          saleId: item.sale_id,
          price: price,
          isSold: isSold,
          id: item.id,
        });
      }

      // console.log("NFTs", itemsList);

      setNfts(itemsList);
      setTotal((total / 50).toFixed(0));
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    loadAigc({}).then(() => onLogin());
  }, []);

  const handleClick = async (filter) => {
    setFilter(filter);
    let myAddressIn = myAddress;

    if (filter === "me" && myAddress === "") {
      myAddressIn = await onLogin();
      // return;
    }
    await loadAigc({ filterIn: filter, myAddressIn: myAddressIn });
  };

  async function buy() {
    if (myAddress === "") {
      await onLogin();
      // return;
    }

    const saleId = selectedItem.saleId;
    const price = selectedItem.price;

    if (saleId < 0 || price <= 0) {
      setOpenBuyDialog(false);
      return;
    }

    setLoading(true);

    const provider = await getProvider();
    const singer = await provider.getSigner();

    const marketplaceContract = new ethers.Contract(
      addressMarket,
      abiMarket,
      singer
    );

    try {
      const options = { value: ethers.parseEther(price.toString()) };
      const tx = await marketplaceContract.buyItem(saleId, options);
      const receipt = await tx.wait();
      console.log(receipt);
      await loadAigc({});
      setOpenBuyDialog(false);
    } catch (error) {
      console.error("Error, buying: ", error);
      setLoading(false);
      alert("Error while buying!");
    }
  }

  async function mint() {
    console.log("Mint Started...", addressToken);
    const provider = await getProvider();

    const singer = await provider.getSigner();

    const artTokenContract = new ethers.Contract(
      addressToken,
      abiToken,
      singer
    );

    try {
      console.log("Mint GetTotalSupply...", addressToken);
      const totalSupply = await artTokenContract.totalSupply();
      const tokenId = Number(totalSupply) + 1;

      console.log("Mint Item", { ...selectedItem, tokenId, myAddress });

      const tx = await artTokenContract.mint(
        `https://api.pixie.xyz/metadata/aigc/${tokenId}`
      );
      const receipt = await tx.wait();

      console.log(receipt);

      const form = new FormData();
      form.append("id", selectedItem.id);
      form.append("token_id", tokenId);

      await postForm(form, "aigc-to-nft");
      const newSelectedItem = { ...selectedItem, tokenId };
      setSelectedItem(newSelectedItem);
      return tokenId;
    } catch (error) {
      console.error("Error, minting: ", error);
      // alert("Error while minting!");
    }
  }

  async function putForSale() {
    setLoading(true);

    let id = selectedItem.tokenId;
    if (id <= 0) {
      id = await mint();
      await new Promise((resolve) => setTimeout(resolve, 6000)); // 3 sec
    }

    console.log("Sale Started...", selectedItem);
    const provider = await getProvider();
    const singer = await provider.getSigner();

    const marketplaceContract = new ethers.Contract(
      addressMarket,
      abiMarket,
      singer
    );

    try {
      const tx = await marketplaceContract.putItemForSale(id, sellPrice);
      const receipt = await tx.wait();

      const saleId = receipt.logs[0].args[0];

      const form = new FormData();
      form.append("id", selectedItem.id);
      form.append("sale_id", saleId);

      await postForm(form, "aigc-to-sale");
      await loadAigc({});

      setOpenSellDialog(false);
    } catch (error) {
      console.error("Error, puting for sale: ", error);
    }
  }

  async function onLogin() {
    try {
      const browserProvider = await detectEthereumProvider();
      const provider = await new BrowserProvider(browserProvider);

      let accounts = await browserProvider.request({
        method: "eth_requestAccounts",
      });
      console.log(accounts);

      setMyAddress(accounts[0]);

      if (typeof accounts === undefined) {
        alert("Please login with Metamask!");
        // <Alert severity="error">This is an error alert — check it out!</Alert>
        console.log("login to metamask");
      }

      const network = await provider.getNetwork();
      const networkId = network.chainId.toString();

      const addressToken = networksToken[networkId].address;
      setAddressToken(addressToken);

      const addressMarket = networksMarket[networkId].address;
      setAddressMarket(addressMarket);

      return accounts[0];
    } catch (error) {
      console.error("Error", error);
    }
  }

  return (
    <>
      <Header account={myAddress} onLogin={onLogin} />
      <div className={classes.homepage}>
        <section className={classes.banner}>
          {!isMobile && (
            <Grid container spacing={0} xs={12} className={classes.gridBanner}>
              <Grid item xs={3}>
                <Grid container spacing={0}>
                  <Grid item xs={8}>
                    <img
                      src={dreaming}
                      alt="dreaming"
                      className={classes.images}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <img
                      src={veterans}
                      alt="veterans"
                      className={classes.images}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <img
                      src={modeling3d}
                      alt="modeling3d"
                      className={classes.images}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <img
                      src={lionKing}
                      alt="lionKing"
                      className={classes.images}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} className={classes.main}>
                {/* <img src={galerie} alt="galerie" /> */}
                <Typography className={classes.logoText}>Pixie</Typography>
                <Typography variant="body2">
                  The First Web3 based Video and AIGC Image Sharing Social
                  Network
                </Typography>
                {/* <Link to="/create-nft">
              <Button variant="contained" color="primary" disableElevation>
                Mint your art
              </Button>
            </Link> */}
              </Grid>
              <Grid item xs={3}>
                <Grid container spacing={0}>
                  <Grid item xs={8}>
                    <img
                      src={stones}
                      alt="dreaming"
                      className={classes.images}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <img
                      src={woman}
                      alt="veterans"
                      className={classes.images}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <img
                      src={wale}
                      alt="modeling3d"
                      className={classes.images}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <img
                      src={comic}
                      alt="lionKing"
                      className={classes.images}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </section>
        <section className={classes.allNfts}>
          {/* <Typography className={classes.title}>Latest artwork</Typography> */}

          <Breadcrumbs aria-label="breadcrumb">
            {/* <Link
            href="#"
            onClick={async (e) => {
              e.preventEvent();
              await handleClick("");
            }}
          > */}
            <Typography
              color={filter === "" ? "inherit" : "textPrimary"}
              variant={filter === "" ? "h5" : "button"}
              className={classes.title}
              onClick={async () => await handleClick("")}
            >
              Latest artwork
            </Typography>
            {/* </Link> */}
            {/* <Link href="#" onClick={async () => await handleClick("me")}> */}
            <Typography
              color={filter === "me" ? "inherit" : "textPrimary"}
              className={classes.title}
              variant={filter === "me" ? "h5" : "button"}
              onClick={async () => await handleClick("me")}
            >
              Mine
            </Typography>
            {/* </Link> */}
          </Breadcrumbs>

          <div className={classes.loadMore}>
            <Pagination
              count={total}
              page={page}
              onChange={async (e, page) => {
                setPage(page);
                await loadAigc({ pageIn: page });
              }}
            />
          </div>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            style={{ minHeight: "100vh" }}
          >
            {nfts.map((nft) => (
              <Grid
                item
                key={nft.uri}
                onClick={() => {
                  setSelectedItem({ ...nft });
                  if (filter === "me") {
                    setOpenSellDialog(true);
                  } else {
                    if (nft.isSold) return;
                    if (nft.owner.toUpperCase() === myAddress.toUpperCase())
                      return;
                    if (nft.saleId < 0 || nft.price <= 0) return;
                    setOpenBuyDialog(true);
                  }
                }}
              >
                <Card {...nft} />
              </Grid>
            ))}
          </Grid>
          <Dialog
            open={openBuyDialog}
            onClose={() => setOpenBuyDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Checkout"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please review the information to ensure it is what you want to
                buy.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenBuyDialog(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={() => buy()} color="primary" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openSellDialog}
            onClose={() => setOpenSellDialog(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Sell</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please input the price and continue.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Price"
                type="number"
                fullWidth
                onChange={(e) => setSellPrice(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenSellDialog(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={() => putForSale()} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <div className={classes.loadMore}>
            <Pagination
              count={total}
              page={page}
              onChange={async (e, page) => {
                setPage(page);
                await loadAigc({ pageIn: page });
              }}
            />
          </div>
        </section>
        <section>
          <div class="pixgram-footer">
            <div class="flex_center" className={classes.footer}>
              <div
                class="flex_column mt20"
                style={{ marginTop: "20px", width: "1000px" }}
              >
                <div
                  class="flex_between flex_items"
                  className={classes.flexBetween}
                >
                  <div class="flex_column">
                    <img
                      class="mt10"
                      src="https://assets.pixie.xyz/mobile/icon/pixgramchain_logo_footer.png"
                      fit="cover"
                      alt="Testnet"
                      className={classes.logoFooter}
                    />
                    <p class="f12 mt10" className={classes.logoCopyright}>
                      ©2021 -&nbsp;2024&nbsp;
                      <a
                        // style="color: #397DFA;"
                        href="https://pixie.xyz"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Pixie.xyz
                      </a>{" "}
                      All rights reserved.
                    </p>
                  </div>
                  <div
                    class="flex_start flex_items"
                    style={{ display: "flex", alignContent: "center" }}
                  >
                    <a
                      style={{ display: "flex", alignItems: "center" }}
                      href="https://www.facebook.com/Pixie-104362245308470"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className={classes.footerImg}
                        // class="mr15"
                        src="https://assets.pixie.xyz/mobile/icon/facebook.png"
                        fit="cover"
                        alt="pixie"
                      />
                    </a>
                    <a
                      style={{ display: "flex", alignItems: "center" }}
                      href="https://twitter.com/PixieApp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className={classes.footerImg}
                        // class="mr15"
                        src="https://assets.pixie.xyz/mobile/icon/twitter.png"
                        fit="cover"
                        alt="pixie"
                      />
                    </a>
                    <a
                      style={{ display: "flex", alignItems: "center" }}
                      href="https://www.youtube.com/channel/UCDb6btQSzsGEGG_UtGDrTJQ"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className={classes.footerImg}
                        // class="mr15"
                        src="https://assets.pixie.xyz/mobile/icon/youtube.png"
                        fit="cover"
                        alt="pixie"
                      />
                    </a>
                    <a
                      style={{ display: "flex", alignItems: "center" }}
                      href="https://t.me/Pixiegroup"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className={classes.footerImg}
                        src="https://assets.pixie.xyz/mobile/icon/telegram.png"
                        fit="cover"
                        // style="width:32px;"
                        alt="pixie"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Backdrop
          // className={classes.backdrop}
          style={{ zIndex: 9999 }}
          open={loading}
          onClick={() => console.log("")}
        >
          {/* <CircularProgress color="inherit" /> */}

          <Box position="relative" display="inline-flex">
            <CircularProgress color="inherit" />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
              textAlign={"center"}
            >
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
                style={{ textAlign: "center", fontSize: "1rem" }}
              >
                {""}
              </Typography>
            </Box>
          </Box>
        </Backdrop>
      </div>
    </>
  );
};

export default Home;
