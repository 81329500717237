import market from "../../contracts/ArtMarketplace.json";
export default market.networks;

// export default {
//   666: {
//     events: {},
//     links: {},
//     address: "0xD288018f45CBfb2b06374282A13d426B5C47eA64",
//     transactionHash:
//       "0x7669c3df09d0f6c5dea32923429604669c0d80f07ac9bb9a52278c6aac16675a",
//   },
//   1337: {
//     events: {},
//     links: {},
//     address: "0xc0Ed46cE8A77185c10a5Dfa301F33e5653915778",
//     transactionHash:
//       "0xe86d4d587bbf596be565fe0b1f25a3dee8350d08ec066ea358d5ea01c5223478",
//   },
//   5777: {
//     events: {},
//     links: {},
//     address: "0xEcB4E07D7754DAF0FD95750af18cd4362dd60750",
//     transactionHash:
//       "0x1fb6fd2cc1fc3b486885e46744da83709e1ff4ff63a832da5648d3748aaeb429",
//   },
//   1629893393320: {
//     events: {},
//     links: {},
//     address: "0x74b7E9e47cA7e2a5a6FB32DaC2e546c4b759bA8e",
//     transactionHash:
//       "0x5e340379514a79a4330696820ee23dbd1e633a80445c9352a7be46baf8532141",
//   },
//   1630103944709: {
//     events: {},
//     links: {},
//     address: "0xD56aeD013D912E45F2888A227EAfe9275fDB1046",
//     transactionHash:
//       "0x9bc02d466cf0056920c9a95a34d9770627c94a63a02b8218c57b0dfc358b0490",
//   },
//   1630357111813: {
//     events: {},
//     links: {},
//     address: "0xE71eE237D0CB1B468516027DEA7E26B56fF6B9eE",
//     transactionHash:
//       "0x9c6126be6b3d997e0d8dcf9a133d494dba59a0be2ed85a285b8b773fcd2bd4ad",
//   },
//   1632944714253: {
//     events: {},
//     links: {},
//     address: "0x6050E8208aD761ee92D33D916385b025C407008f",
//     transactionHash:
//       "0x5a024da0f3f5717247ed5715d9ce88057523f60d6edc967cca19ab7572c6a538",
//   },
// };
