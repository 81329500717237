import React from "react";
import { Link } from "react-router-dom";
import Web3 from "web3";

import { Card as MuiCard } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import SvgIcon from "@material-ui/core/SvgIcon";
import Divider from "@material-ui/core/Divider";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

import { useStyles } from "./styles.js";
// import { ReactComponent as EthereumLogo } from "../../assets/ethereum_logo.svg";
// import { ReactComponent as EthereumLogo } from "../../assets/pixie_logo.png";

const Card = ({
  tokenId,
  name,
  image,
  price,
  owner,
  description,
  isForSale,
  isSold,
}) => {
  const classes = useStyles();
  // console.log("Card: ", tokenId, price);
  return (
    // <Link to={`/nft/${tokenId}`}>
    <MuiCard className={classes.root}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt={name}
          height="240"
          image={image}
          title={name}
        />
        <CardContent className={classes.content}>
          <div className={classes.title}>
            <Typography
              className={"MuiTypography--heading"}
              variant={"h5"}
              gutterBottom
            >
              {name}
            </Typography>
            {isSold && (
              <Chip
                size="small"
                disabled={true}
                label="Sold"
                color="primary"
                className={classes.badge}
              />
            )}
            {!isSold && isForSale && (
              <Chip
                size="small"
                disabled={true}
                label="Selling"
                color="secondary"
                className={classes.badge}
              />
            )}
            {!isForSale && (
              <Chip
                size="small"
                disabled={true}
                label=""
                className={classes.nobadge}
              />
            )}
          </div>
          <Typography
            variant="body2"
            component="body2"
            style={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              minHeight: "60px",
            }}
          >
            {description}
          </Typography>
          {price > 0 && (
            <Typography variant="h6" className={classes.price}>
              {/* <SvgIcon
                component={EthereumLogo}
                viewBox="0 0 400 426.6"
                titleAccess="ETH"
              /> */}
              <img
                src="https://assets.pixie.xyz/mobile/icon/pixie-chain.png"
                alt="pixie-chain"
                className={classes.tokenLogo}
              />
              {/* <span>{Web3.utils.fromWei(String(price), "ether")}</span> */}
              <span>{price.toString()}</span>
            </Typography>
          )}
          {price <= 0 && (
            <Typography variant="body" className={classes.price}>
              <LocalOfferIcon fontSize="small" style={{ marginRight: "5px" }} />
              Make Offer
            </Typography>
          )}
          <Divider className={classes.divider} light />
          <Typography
            variant={"body1"}
            align={"center"}
            className={classes.seller}
          >
            {owner.slice(0, 7)}...{owner.slice(-4)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </MuiCard>
    // </Link>
  );
};

export default Card;
