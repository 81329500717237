import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  header: {
    // background: "#3F51B5",
    // background: "rgb(255, 121, 0)",
    background: "white",
  },
  logo: {
    width: "6rem",
  },
  account: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
  },
  walletIcon: {
    marginRight: "0.4rem",
    color: "rgb(255, 121, 0)",
  },
  mainColor: {
    color: "rgb(255, 121, 0)",
  },
});

export { useStyles };
