import axios from "axios";

const baseUrl = "https://api.pixie.xyz/nft/";
export const api = axios.create({
  // baseURL: process.env.API_URL || 'http://localhost:3333/'
  baseURL: process.env.API_URL || baseUrl,
});

export const postForm = async (params, url) => {
  const options = {
    method: "POST",
    url: `${baseUrl}${url}`,
    headers: {
      "content-type": "multipart/form-data;",
      Origin: "https://nft.pixie.xyz",
      Host: "pixie-middleware:18088",
      Referer: "https://nft.pixie.xyz",
    },
    data: params,
  };

  try {
    const { data } = await axios.request(options);
    console.log("Updated", data);
    return data;
  } catch (error) {
    console.error(error);
  }
};
