import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  homepage: {},
  images: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
  banner: {
    // minHeight: "50vh",
    // maxHeight: "50vh",
    // marginBottom: "3rem"
  },
  gridBanner: {
    // margin: 0,
    // width: '100%',
  },
  main: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 1rem",
    "& img": {
      width: "55%",
    },
    "& p": {
      margin: "3rem 8rem 4rem 8.5rem",
      fontSize: "1.2rem",
      textAlign: "center",
    },
    "& button": {
      textTransform: "none",
      fontSize: "1.2rem",
      fontWeight: "400",
      background: "#3F51B5",
    },
  },
  allNfts: {
    marginTop: "2rem",
    padding: "0 2rem",
  },
  title: {
    // fontFamily: "sans-serif",
    // fontSize: "1.8rem",
    // fontWeight: "600",
    // marginBottom: "1rem",
    cursor: "pointer",
  },
  loadMore: {
    // textAlign: "center",
    margin: "20px 0",
    display: "flex",
    justifyContent: "center",
  },
  logoText: {
    color: "rgb(255, 121, 0)",
    fontSize: "4.5vw !important",
    display: "inline-block",
    fontWeight: "bold",
  },
  footer: {
    background: "#111",
    height: "124px",

    // -webkit-box-pack: center;
    // -ms-flex-pack: center;
    // display: -webkit-box;
    // display: -ms-flexbox;
    display: "flex",
    justifyContent: "center",
  },
  footerImg: {
    width: "32px",
    marginRight: "15px",
  },
  logoFooter: {
    width: "187px",
  },
  logoCopyright: {
    lineHeight: "1.5em",
    margin: 0,
    color: "#999898",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",

    // -webkit-box-pack: justify;
    // -ms-flex-pack: justify;
    // display: -webkit-box;
    // display: -ms-flexbox;
    // display: flex;
    // justify-content: space-between;
  },
});

export { useStyles };
