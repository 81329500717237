import market from "../../contracts/ArtMarketplace.json";
export default market.abi;

// export default [
//   {
//     inputs: [
//       {
//         internalType: "contract ArtToken",
//         name: "_token",
//         type: "address",
//       },
//     ],
//     stateMutability: "nonpayable",
//     type: "constructor",
//   },
//   {
//     anonymous: false,
//     inputs: [
//       {
//         indexed: false,
//         internalType: "uint256",
//         name: "id",
//         type: "uint256",
//       },
//       {
//         indexed: false,
//         internalType: "uint256",
//         name: "tokenId",
//         type: "uint256",
//       },
//       {
//         indexed: false,
//         internalType: "uint256",
//         name: "price",
//         type: "uint256",
//       },
//     ],
//     name: "itemAddedForSale",
//     type: "event",
//   },
//   {
//     anonymous: false,
//     inputs: [
//       {
//         indexed: false,
//         internalType: "uint256",
//         name: "id",
//         type: "uint256",
//       },
//       {
//         indexed: false,
//         internalType: "address",
//         name: "buyer",
//         type: "address",
//       },
//       {
//         indexed: false,
//         internalType: "uint256",
//         name: "price",
//         type: "uint256",
//       },
//     ],
//     name: "itemSold",
//     type: "event",
//   },
//   {
//     inputs: [
//       {
//         internalType: "uint256",
//         name: "",
//         type: "uint256",
//       },
//     ],
//     name: "activeItems",
//     outputs: [
//       {
//         internalType: "bool",
//         name: "",
//         type: "bool",
//       },
//     ],
//     stateMutability: "view",
//     type: "function",
//     constant: true,
//   },
//   {
//     inputs: [
//       {
//         internalType: "uint256",
//         name: "",
//         type: "uint256",
//       },
//     ],
//     name: "itemsForSale",
//     outputs: [
//       {
//         internalType: "uint256",
//         name: "id",
//         type: "uint256",
//       },
//       {
//         internalType: "uint256",
//         name: "tokenId",
//         type: "uint256",
//       },
//       {
//         internalType: "address payable",
//         name: "seller",
//         type: "address",
//       },
//       {
//         internalType: "uint256",
//         name: "price",
//         type: "uint256",
//       },
//       {
//         internalType: "bool",
//         name: "isSold",
//         type: "bool",
//       },
//     ],
//     stateMutability: "view",
//     type: "function",
//     constant: true,
//   },
//   {
//     inputs: [
//       {
//         internalType: "uint256",
//         name: "tokenId",
//         type: "uint256",
//       },
//       {
//         internalType: "uint256",
//         name: "price",
//         type: "uint256",
//       },
//     ],
//     name: "putItemForSale",
//     outputs: [
//       {
//         internalType: "uint256",
//         name: "",
//         type: "uint256",
//       },
//     ],
//     stateMutability: "nonpayable",
//     type: "function",
//   },
//   {
//     inputs: [
//       {
//         internalType: "uint256",
//         name: "id",
//         type: "uint256",
//       },
//     ],
//     name: "buyItem",
//     outputs: [],
//     stateMutability: "payable",
//     type: "function",
//     payable: true,
//   },
//   {
//     inputs: [],
//     name: "totalItemsForSale",
//     outputs: [
//       {
//         internalType: "uint256",
//         name: "",
//         type: "uint256",
//       },
//     ],
//     stateMutability: "view",
//     type: "function",
//     constant: true,
//   },
// ];
