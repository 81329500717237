import artToken from "../../contracts/ArtToken.json";
export default artToken.networks;

// export default {
//   666: {
//     events: {},
//     links: {},
//     address: "0x0927853B72C93de1aE5450bFFbfaF8AA70101587",
//     transactionHash:
//       "0x700c734b9d0c90243c2b4da5b593bc4801fdac7c2bf166af7c9b0364281d40f1",
//   },
//   1337: {
//     events: {},
//     links: {},
//     address: "0x9218a30830188514a722F3E198044B5866CE8eA7",
//     transactionHash:
//       "0xb4451ae4ac51ad6140bee1b45b4473ecfd67321831e3d7c1c0180b42ae726fe9",
//   },
//   5777: {
//     events: {},
//     links: {},
//     address: "0x8Bb5Fd8F0428E644395981FAdab18cA6FfFF4101",
//     transactionHash:
//       "0x84ed361df619c1802a453b1df994da9678c214209b4c1abe3a9dac6c63058f90",
//   },
//   1629893393320: {
//     events: {},
//     links: {},
//     address: "0xd61Fe6ee2B461d9Ee8120314D206Fc4083542A72",
//     transactionHash:
//       "0x57c87b218119195eb6780b2ef3d74ff00c457e58d222e685f17cbb5577d4efc4",
//   },
//   1630103944709: {
//     events: {},
//     links: {},
//     address: "0x290f9B2dd0D71A70eb881aE47ce3dD93946d58e4",
//     transactionHash:
//       "0xdc7ca1cccf3019eede5994d810cda94b8ae925b89b656f98239e65bba8162533",
//   },
//   1630357111813: {
//     events: {},
//     links: {},
//     address: "0xb6896FD0592dfdB9976b76a97f34fc2D00Da3Caa",
//     transactionHash:
//       "0x43721a7ad7a5f038ca81d59a43b88faf5056c7d6202954e1a8e37916fd4c9de8",
//   },
//   1632944714253: {
//     events: {},
//     links: {},
//     address: "0xdfb3E74c153cc87302a38D30dAC6A74BAcCab3e3",
//     transactionHash:
//       "0x5657502d8c5ddee7bf09f72185e8e1e09e763316c9a642c32930fc933fb4997c",
//   },
// };
